<template>
	<div class="validate-email pt-4">
		<div class="box px-4 py-4 mt-1">
			<div class="row justify-content-center">
				<div class="col-lg-4 text-center">
					<template v-if="validating">
						<h3>{{$t('validateEmail.validating')}}</h3>
						<spinner v-if="validating" />
					</template>
					<template v-else-if="validated">
						<h3>{{$t('validateEmail.validated')}}</h3>
						<p>{{$t('validateEmail.yourEmailHasBeenValidated')}}</p>
					</template>
					<template v-else>
						<h3>{{$t('terms.error')}}</h3>
						<p>{{$t('errors.anErrorOccurredTryAgain')}}</p>
					</template>
					<router-link :to="{name:'login',params: {lang: $i18n.locale}}"><div class="btn btn-secondary">{{$t('terms.login')}}</div></router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Spinner from "@/components/loaders/Spinner";
	import SmallButton from "@/components/buttons/SmallButton";
	import StandardButton from "@/components/buttons/StandardButton";
	export default {
		name: "ValidateEmail",
		components: {StandardButton, SmallButton, Spinner},
		data(){
			return {
				validating: true,
				validated: false
			}
		},
		methods: {
			checkToken(){
				this.validating = true;
				this.validated = false;
				this.$concreteApi.get(undefined,'/account/validate-email/'+this.$route.params.token,true)
				.then(response => {
					if(typeof response.validated !== 'undefined' && response.validated === true)
					{
						this.validated = true;
					}
					console.log(response)
				})
				.then(() => {
					this.validating = false;
				},() => {
					this.validating = false;
				})
			}
		},
		mounted()
		{
			this.checkToken();
		}
	};
</script>

<style lang="scss" scoped>

</style>
